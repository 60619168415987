@import url('https://fonts.googleapis.com/css2?family=Nova+Cut&display=swap');
.artist-body {
    background-color: black;
    color: bisque;
    padding: 10px;
    padding-top: 50px;
}

.artist_card_box {
    margin: 0 auto;
    max-width: 330px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    min-height: 350px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 13px !important;
    border: 1.5px solid #fde59c;
    overflow: hidden;
    flex-direction: column;
    background-color: #333;
}

.artist_card_img {
    min-height: 290px;
    max-height: 290px;
}

.swiper {
    width: 100%;
    height: 100%;
    user-select: none;
}

.artist-socials {
    display: flex;
    color: aliceblue;
    align-items: center;
    justify-content: center;
}

.artist-socials i {
    width: 35px;
    height: 35px;
    background-color: #5f5f5f;
    border-radius: 50%;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fde59c;
    letter-spacing: 0px;
}

.artist-card-text {
    margin: 19px auto 8px auto;
    padding-top: 20px;
    padding-bottom: 10px;
    letter-spacing: 5px;
}

.artist-card-text p {
    font-size: 15px;
}

.artist-card-text span {
    margin-top: -10px !important;
    color: #fff;
    font-size: 14px;
    position: relative;
    top: -10px;
}

.swiper-slide img.img-card {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* 
.swiper-button-prev:after {
    background-image: url("../../asset/artist/leftbtn.png") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: none !important;
    font-size: 75px !important;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-feature-settings: none;
    font-variant: initial;
    line-height: 1;
    content: "prev" !important;
    color: rgba(255, 255, 255, 0);
    margin-left: 150px;
    z-index: 10;
    cursor: pointer;
    filter: drop-shadow(0 0 15px #000);
}

.swiper-button-next:after {
    background-image: url("../../asset/artist/rightbtn.png") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: none !important;
    font-size: 60px !important;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-feature-settings: none;
    font-variant: initial;
    line-height: 1;
    content: "prev" !important;
    color: rgba(255, 255, 255, 0);
    margin-right: 330px;
    filter: drop-shadow(0 0 15px #000);
} */

.custom_arrows_artist {
    position: absolute;
    top: 42%;
    z-index: 999;
    user-select: none;
    cursor: pointer;
    width: 72px;
    max-width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom_arrows_artist img {
    width: 100%;
    filter: drop-shadow(0 0 35px 50px #fde59c);
}

.custom_arrows_artist.left_cs_arrow {
    left: 3%;
}

.custom_arrows_artist.right_cs_arrow {
    right: 3%;
}

.slider-img {
    width: 90%;
}

.roundSlider {
    background-color: #000;
    background-image: url(../../asset/gallery/gallery_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: calc(100vh + 220px);
    display: flex;
    align-items: center;
}

.roundSlider_container {
    max-width: 1550px;
    margin: auto;
}

.gallery_swiper_main {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 86.9%;
    margin: 0 auto;
    padding: 65px 0 52px 0;
}

@media (min-width: 1700px) {
    .gallery_swiper_main {
        max-width: 88%;
    }
}

.roundSlider .custom_arrows_artist.right_cs_arrow {
    right: 2%;
}

.roundSlider .custom_arrows_artist.left_cs_arrow {
    left: 2%;
}

.roundSlider .custom_arrows_artist {
    top: 40%;
}

.galley_slider_img_wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.galley_slider_img_wrapper img {
    width: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    filter: blur(3.6px);
}

.galley_slider_img_wrapper::before {
    transition: opacity .5s ease;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.83) -4.01%, rgba(0, 0, 0, 0.26) 86.46%);
}

.swiper-slide-active .galley_slider_img_wrapper {
    transform: scale(1.3)!important;
    box-shadow: 0 0 15px #fde59c;
}

.swiper-slide-active .galley_slider_img_wrapper::before {
    opacity: 0;
}

.swiper-slide-active .galley_slider_img_wrapper img {
    /* transform: scale(1.07); */
    filter: blur(0px);
}

.swiper-slide-next .galley_slider_img_wrapper img,
.swiper-slide-prev .galley_slider_img_wrapper img {
    filter: blur(1.5px);
}

.galley_slider_img {
    max-width: 550px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.artist-body {
    position: relative;
}

.artist-body::before {
    content: "";
    position: absolute;
    top: 150px;
    left: 0;
    height: 150px;
    z-index: 9;
    width: 0px;
    box-shadow: 0 0 120px 120px rgba(0, 0, 0, 0.952);
}

.artist-body::after {
    content: "";
    position: absolute;
    top: 150px;
    right: 0;
    height: 150px;
    box-shadow: 0 0 120px 120px rgba(0, 0, 0, 0.89);
}

.artist-dropdown-content {
    display: none;
}

.artist-dropdown-content-show {
    display: block;
}

.img-arr img {
    width: 20px;
    margin: 0 auto;
    cursor: pointer;
}

.img-arr-active img {
    transform: rotate(180deg);
}

.artist-content-img {
    padding: 0px 8px;
}

.artist-dropdown-content p {
    font-size: 12px;
    color: #fde59c;
    margin-top: 8px;
}

.artist_heading {
    display: none;
}

.pixivIcon {
    width: 35px;
    height: 35px;
    background-color: #5f5f5f;
    border-radius: 50%;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fde59c;
    letter-spacing: 0px;
    padding: 7px
}

.raseImg {
    background-image: url(../../asset/gallery/rase.svg);
    background-size: 100%;
    height: 104px;
    width: 259px;
    margin: 0 auto;
    color: var(--primary);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 16px;
    font-size: 36px;
}

.raseImg span {
    font-family: 'Nova Cut', cursive;
    white-space: nowrap;
}


/* .raseImg img {
    max-width: 100%;
    filter: drop-shadow(0 0 15px #ad995c);
} */

@media only screen and (max-width: 780px) {
    .artist-body::before {
        height: 100%;
        top: 200px;
        box-shadow: 0 0 40px 70px #000;
    }
    .artist-body::after {
        height: 100%;
        top: 200px;
        box-shadow: 0 0 40px 70px #000;
    }
    .swiper-button-prev:after {
        margin-left: 30px;
    }
    .swiper-button-next:after {
        margin-right: 30px;
    }
    .swiper-slide-active .galley_slider_img_wrapper {
        transform: scale(1.5)!important;
    }
    .gallery_swiper_main {
        max-width: 100%;
        padding: 55px 0 22px 0;
    }
}

@media (max-width: 480px) {
    .artist-body {
        padding-top: 20px;
    }
    .artist_card_box {
        max-width: 89%;
    }
    .artist-card-text {
        padding: 15px 10px;
    }
    .artist-body::before {
        box-shadow: 0 0 30px 30px rgba(0, 0, 0, 0.5);
    }
    .artist-body::after {
        box-shadow: 0 0 30px 30px rgba(0, 0, 0, 0.5);
    }
    .artist_heading {
        display: block;
        margin: 28px auto 45px auto;
        text-align: center;
        width: 100%;
    }
    .artist_heading img {
        width: 90%;
        max-width: 90%;
        margin: 0 auto;
    }
    .custom_arrows_artist {
        top: 55%;
        width: 50px;
        max-width: 50px;
    }
    .custom_arrows_artist.left_cs_arrow {
        left: 1%;
    }
    .custom_arrows_artist.right_cs_arrow {
        right: 1%;
    }
    .roundSlider {
        min-height: 470px;
    }
    .galley_slider_img {
        max-width: 320px;
    }
    .raseImg {
        font-size: 34px;
    }
}