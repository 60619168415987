.ourteam-section {
    background-color: black;
}

.card-wraper {
    color: #fde59c;
    margin: 0 5%;
}

.team-card {
    border: 1.5px solid #fde59c;
    border-radius: 20px;
    padding: 20px;
}

.desktop-team-members .team-card {
    height: 640px;
}

.card-img {
    width: 50% !important;
}

.ourteam img {
    width: 100%;
}

.team-des {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    text-align: center;
    letter-spacing: 0.095em;
}

.team-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 176.5%;
    /* or 42px */
    text-align: center;
    letter-spacing: 0.095em;
}

.mobile-team-section-swiper {
    display: none;
}

.mobile-team-section-swiper {
    color: #fde59c;
}

.team-member-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-team-section-swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    border-radius: 0px !important;
    overflow: hidden;
    border: 1px solid transparent;
    padding: 50px 0px;
}

.mobile-team-section-swiper .swiper-pagination-bullet {
    background: #b9b4b4 !important;
}

@media only screen and (max-width:768px) {
    .mobile-team-section-swiper .swiper-slide {
        height: unset !important;
        min-height: unset !important;
        padding: 0;
    }
    .team-card {
        height: unset !important;
        width: 95% !important;
        border: 1.5px solid #fde59c !important;
        border-radius: 20px !important;
        padding: 20px !important;
        margin: 22px auto;
    }
}

@media only screen and (max-width:550px) {
    .mobile-team-section-swiper {
        display: block;
    }
    .desktop-team-members {
        display: none;
    }
}