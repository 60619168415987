.contactus-beg{
    background-color: #fff;
    margin-top: 50px;
    padding-bottom: 50px;
    padding-top: 50px;
}
.contactus-beg p{
   letter-spacing: 5px;
   font-weight: 600;
}
.form-style{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form-style textarea{
    height: 100px;
}
.form-style button{
    background-color: #fde59c;
    color: #888;
    letter-spacing: 10px;
}
.input-style{
    width: 50%;
    padding: 10px;
    margin: 10px;
    outline: none;
    border-radius: 5px;
    border: 1.5px solid #fde59c;
}
@media only screen  and (max-width:768px){
    .input-style{
        width: 80%;
        
    }
}