@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.minit-res {
    position: relative;
    padding: 60px;
    color: #fde59c;
}

.desktop-three-back {
    background-color: #222;
    padding-top: 100px;
}

.corner1 {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.corner2 {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.corner3 {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.corner4 {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.circle-img {
    width: 67%;
    /* margin-bottom: 40px; */
}

.endImg {
    width: 30%;
    padding-bottom: 50px;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.img-container p {
    color: #fde59c;
    letter-spacing: 5px;
    margin-top: 30px;
    padding-bottom: 35px;
}

.mint-banner-background-img {
    background: url(../../Component/asset/image/metaverseBeg.jpg);
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.trianglebtnLeft {
    color: white;
    width: 0px;
    height: 0px;
    border: 30px solid black;
    border-top-color: transparent;
    border-right-color: white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    cursor: pointer;
    background-color: transparent;
}

.mint_count_box {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    width: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.trianglebtnRight {
    color: white;
    width: 0px;
    height: 0px;
    border: 30px solid black;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: white;
    cursor: pointer;
    background-color: transparent;
}

.mint_wrapper_box {
    border-radius: 30px;
    background-color: rgba(10, 10, 10, 0.57);
}

@media screen and (max-width: 768px) {
    .minit-res {
        padding: 72px 22px 50px 22px
    }
    .circle-img {
        width: 100%;
        margin-bottom: 22px;
    }
    .endImg {
        margin-top: 22px;
        width: 58%;
    }
    .mint-banner-background-img {
        min-height: 70vh;
    }
    .desktop-three-back {
        padding-top: 50px;
    }
    .mint_count_box {
        width: 42px;
        height: 46px
    }
    .trianglebtnLeft {
        border: 23px solid black;
        border-top-color: transparent;
        border-right-color: white;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    .trianglebtnRight {
        border: 23px solid black;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: white;
    }
}