.footer{
    background-color: black;
    padding-bottom: 1%;
    padding-top: 30px;
}
.footer-header{
    color: #fde59c;
}
.footer-soc-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
}
.footer-soc-icon i{
    font-size: 25px;
    margin: 15px;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,.2);
    color: #fde59c;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content:center ;
}
.footer-soc-icon::after{
    position: absolute;
    content: '';
    background-color: #fde59c;
    height: 1px;
    width: 60%;
    top: 100px;
}
.footer-text{
    color: #fde59c;
}
.scrollTop{
    height: 40px;
    width: 40px;
    background-color:transparent;
    border-radius: 50%;
    color: #fde59c;
    border:1px solid #fde59c;
    outline: none;
    padding: 5px;
    margin-bottom: 20px;
    font-size: 18px;
}