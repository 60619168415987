.metaverse{
    background-image: url('../../asset/image/metaverseBeground.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
}
.metaHeading{
    color: #FDE59C;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 5px;
    margin-top: 50px !important;
}
.meta-description{
    color: #FDE59C;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 176.5%;
    /* or 25px */
    padding: 0px 200px;
    text-align: center;
    letter-spacing: 0.095em;
}
.metaVerseVideo {
    width: 100%;
    border: 2px solid #FDE59C;
    padding-top: 50px;
    padding-bottom: 30px;
    border-radius: 20px;
    margin-top: 100px;
    height:auto;
}
.metaVerseVideo embed{
   width: 80%;
   height:  502px!important;
}
@media only screen and (max-width:979px){
    .metaVerseVideo embed{
        width: 90%;
        height:  300px!important;
     } 
}
@media only screen and (max-width:769px){
    .metaVerseVideo embed{
        width: 90%;
        height:  200px!important;
     } 
     .meta-description{
         padding: 24px 0px;
     }
     .metaVerseVideo {
        margin: 0;
        border: none; 
        padding-top: 0px; 
        padding-bottom: 0px;
     }
}