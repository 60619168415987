/* #menuToggle {
    display: block;
    position: relative;
    top: 50px;
    left: 50px;
    z-index: 99;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle a {
    text-decoration: none;
    color: #fde59c;
    transition: color 0.3s ease;
}

#menuToggle a:hover {
    color: gold;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
}



#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #fde59c;
    ;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}


#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #fde59c;
    ;
}


#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}


#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}


#menu {
    position: absolute;
    width: 300px;
    left: 0;
    top: 0;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    text-align: left;
    background: #000;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#navigation {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(179, 81, 81);
}

#menu li {
    padding: 10px 0;
    font-size: 22px;
}

#menuToggle input:checked~ul {
    transform: none;
} */

#cs_navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    text-align: left;
    user-select: none;
    width: 300px;
    background: rgb(0, 0, 0);
    padding: 100px 16px 24px 33px;
    transform: translateX(-350px);
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    max-height: calc(100vh - 10px);
    overflow: auto;
}

#cs_navigation::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(32, 32, 32);
}

#cs_navigation::-webkit-scrollbar-thumb {
    /* width: 8px; */
    background-color: var(--primary);
    border-radius: 50px;
}

#cs_navigation.opened_nav {
    transform: translateX(0);
}

.nav_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: rgba(24, 23, 23, 0.178);
}

#cs_menuToggle {
    position: fixed;
    z-index: 99999;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    outline: none;
    border: none;
    top: 20px;
    left: 33px;
}

#cs_menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #fde59c;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

#cs_menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#cs_menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#cs_menuToggle.opened_nav span {
    background: #fde59c;
}

#cs_menuToggle.opened_nav span:nth-last-child(1) {
    transform: rotate(-41deg) scale(1.1, 1) translateY(3px) translateX(-1.5px);
}

#cs_menuToggle.opened_nav span:nth-last-child(2) {
    opacity: 0;
    background: purple;
}

#cs_menuToggle.opened_nav span:nth-last-child(3) {
    transform: rotate(41deg) scale(1.1, 1) translateX(3px) translateY(-3px);
}

#cs_menu {
    padding-left: 0;
    overflow: auto;
}

#cs_menu li {
    padding-bottom: 14px;
}


/* #cs_menu.opened_nav {
    transform: translateX(0);
} */

#cs_menu a {
    text-decoration: none;
    color: #fde59c;
    transition: color 0.3s ease;
    font-size: 22px;
    font-weight: 400;
}