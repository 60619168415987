.giveWay {
    background-image: url('../../asset/image/giveWayBack.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;
}

.wrapper {
    position: relative;
    z-index: 3;
}

.giveWay::before {
    position: absolute;
    content: '';
    background-color: rgba(0, 0, 0, 0.63);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.giveWayHeading {
    color: #fde59c;
    letter-spacing: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 176.5%;
    /* or 35px */
    align-items: center;
    text-align: center;
    letter-spacing: 0.3em;
}

.lotery-text {
    color: #fde59c;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 176.5%;
    /* or 35px */
    text-align: center;
    letter-spacing: 0.3em;
}

.coins {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* .coins img {
 width: 20% !important;
 padding: 20px;
} */

.coin-des {
    color: #fde59c;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px
}

.under-coin {
    font-size: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    text-align: center;
    letter-spacing: 0.095em;
}


/* .giveaway-cards-gb {
    background: #000;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #fde59c;
    padding-top: 45px;
}
.giveaway-cards-gb .eventCard {
    padding: 0;
    border-radius: 0px;
    border: none;
}
.giveaway-cards-gb .eventCard{
    margin-top: -32px;
} */


/* .giveaway-cards-2-bg {
    background: #000;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #fde59c;
    padding-top: 45px;
}
.giveaway-cards-2-bg .eventCard {
    padding: 0;
    border-radius: 0px;
    border: none;
}
.giveaway-cards-2-bg .eventCard{
    margin-top: -32px;
} */

@media only screen and (min-width:979px) {
    .give-ways-des {
        margin: 0 215px !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 176.5%;
        /* or 28px */
        text-align: center;
        letter-spacing: 0.095em;
    }
}

@media only screen and (max-width:489px) {
    .giveaway-cards-gb,
    .giveaway-cards-2-bg {
        background: #000;
        padding-bottom: 16px;
        border-radius: 8px;
        border: 1px solid #fde59c;
        padding-top: 45px;
    }
    .giveaway-cards-gb .eventCard,
    .giveaway-cards-2-bg .eventCard {
        padding: 0;
        border-radius: 0px;
        border: none;
    }
    .giveaway-cards-gb .eventCard,
    .giveaway-cards-2-bg .eventCard {
        margin-top: -32px;
    }
}

.coins .img-fluid-coins {
    width: 100%;
    max-width: 200px;
    padding: 20px;
}

@media (max-width:480px) {
    .coins .img-fluid-coins {
        width: 100%;
        max-width: 160px;
        padding: 16px;
    }
    .lotery-text,
    .giveWayHeading {
        font-size: 1rem!important;
    }
}