.chain-voting {
    background-image: url('../../asset/image/votingBack.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-bottom: 2%;
}

.chain-voting::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(3, 0, 0, 0.7);
    z-index: 1;
}

.logo-part {
    position: relative;
    z-index: 2;
    padding-top: 50px;
    padding-bottom: 100px;
}

.voting-des {
    color: #fd9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    letter-spacing: 0.095em;
}

.listing {
    list-style: disc;
}

.voting-des-box {
    border: 1.5px solid #fd9;
    border-radius: 20px;
    position: relative;
    z-index: 2;
}

@media (max-width:550px) {
    .voting-des {
        text-align: center;
    }
}

.voting-learn-more button {
    border: none;
    outline: none;
    border: 1px solid #fd9;
    border-radius: 8px;
    padding: 7px 16px;
    background: transparent;
    color: #fd9;
    display: none;
}

@media (max-width:550px) {
    .voting-learn-more {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .voting-learn-more button {
        display: block;
    }
    .show-voting-text {
        display: none;
    }
    .show-voting-text-active {
        display: block;
    }
    .logo-part {
        position: relative;
        z-index: 2;
        padding-top: 22px;
        padding-bottom: 40px;
    }
    .bonuse-section .logo-part {
        padding-top: 53px;
    }
    .bonuse-section .logo-part img {
        width: 65%;
    }
}