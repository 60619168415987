.utilities {
    background-color: black;
    padding-bottom: 40px;
}

.utilities-heading {
    color: #FDE59C;
    letter-spacing: 10px;
    padding-top: 20px;
    padding-bottom: 60px;
    font-size: 14px;
}

.utilities-points {
    color: #bca666;
    margin: 0 25%;
    text-align: left;
    list-style: circle;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
    line-height: 176.5%;
    letter-spacing: 0.3em;
}


/* .utilities-points ul li{
    list-style:disc;
    margin-top: 50px;
} */

.point-heading {
    font-size: 16px;
    font-weight: 700;
    color: #FDE59C;
    letter-spacing: 0.3em;
    font-style: normal;
    line-height: 176.5%;
    padding-bottom: 30px;
}

li {
    padding-bottom: 20px;
}

@media only screen and (max-width:789px) {
    .utilities-points {
        margin: 0 5%;
    }
}

.mobile-utility-swiper {
    display: none;
}

.mobile-utility-swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px !important;
    border: none;
    overflow: hidden;
    flex-direction: column;
    background-color: transparent;
}

.mobile-utility-swiper .swiper-pagination-bullet {
    background: #b9b4b4 !important;
}

@media (max-width:550px) {
    .desktop-utility {
        display: none;
    }
    .mobile-utility-swiper {
        display: block;
    }
    .utilities-points li {
        padding: 0;
        margin: 0;
    }
    .mobile-utility-swiper .swiper-slide p {
        font-size: 15px;
    }
}